import { Button } from '@telia-company/tv.oneapp-web-ui';
import { RadixModal } from 'components/RadixModal';
import { useLogger } from 'hooks';
import { FormEvent, useEffect, useState } from 'react';
import { StyledDescription, StyledPinField } from './BasePinCodeModalContent.styles';
import { useTranslation } from 'react-i18next';
import { changeRentalPinCode } from 'services/userService';

type SetPinCodeModalContentProps = {
  oldPin: string;
  open: boolean;
  onDone: () => void;
  onAbort: () => void;
};

export const SetPinCodeModalContent = ({
  oldPin,
  open,
  onDone,
  onAbort,
}: SetPinCodeModalContentProps) => {
  const logger = useLogger('SetPinCodeModalContent');
  const { t } = useTranslation();
  const [newPin, setNewPin] = useState('');
  const [verifyPin, setVerifyPin] = useState('');
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isVerifyingPinCode, setIsVerifyingPinCode] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isVerifyingPinCode) {
      setIsVerifyingPinCode(true);
      setErrorMessage('');
      return;
    } else {
      if (newPin !== verifyPin) {
        setErrorMessage(t('SETTINGS_LOCKER_PIN_CODE_MATCH_ERROR'));
        return;
      } else {
        setBusy(true);
        try {
          await changeRentalPinCode(oldPin, newPin);
          onDone();
        } catch (err) {
          logger.error(`Failed to set new PIN for user`, err);
          setErrorMessage(t('SETTINGS_LOCKER_WRONG_PIN_ERROR'));
        } finally {
          setBusy(false);
        }
      }
    }
  };

  // reset on open
  useEffect(() => {
    if (open) {
      setErrorMessage('');
      setNewPin('');
      setVerifyPin('');
      setIsVerifyingPinCode(false);
    }
  }, [open]);

  return (
    <RadixModal.Content title={t('SETTINGS_LOCKER_CHANGE_PIN_CODE')}>
      <StyledDescription>
        {isVerifyingPinCode
          ? t('RESET_PURCHASE_PIN_VERIFY_PIN_LABEL')
          : t('SETTINGS_LOCKER_SET_PIN_CODE_INFO')}
      </StyledDescription>
      <form onSubmit={onSubmit}>
        {isVerifyingPinCode ? (
          <StyledPinField
            key="verifyPin"
            value={verifyPin}
            onValueChanged={(value) => {
              setVerifyPin(value);
            }}
            length={4}
            masked={true}
            state={errorMessage ? 'INVALID' : undefined}
            message={errorMessage}
          />
        ) : (
          <StyledPinField
            key="newPin"
            value={newPin}
            onValueChanged={(value) => {
              setNewPin(value);
            }}
            length={4}
            masked={true}
            state={errorMessage ? 'INVALID' : undefined}
            message={errorMessage}
          />
        )}
        <RadixModal.ButtonStrip>
          <Button type="button" variant="secondary" onClick={() => onAbort()}>
            {t('COMMON_BUTTON_CANCEL')}
          </Button>
          <Button
            type="submit"
            isLoading={busy}
            disabled={busy}
            variant={newPin.length === 4 ? 'primary' : 'secondary'}
          >
            {t('SETTINGS_LOCKER_SET_PIN_CODE_BUTTON_NEXT')}
          </Button>
        </RadixModal.ButtonStrip>
      </form>
    </RadixModal.Content>
  );
};
