/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { RadixModal } from 'components/RadixModal';
import { useLogger, useSnackbars } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { StyledDescription, StyledPinField } from './BasePinCodeModalContent.styles';
import { useTranslation } from 'react-i18next';
import { forgotPinCode, getUser, validateRentalPinCode } from 'services/userService';
import { SetPinCodeModalContent } from './SetPinCodeModalContent';

type ChangePinCodeModalContentProps = {
  open: boolean;
  onDone: () => void;
  onAbort: () => void;
};

export const ChangePinCodeModalContent = ({
  open,
  onDone,
  onAbort,
}: ChangePinCodeModalContentProps) => {
  const logger = useLogger('ChangePinCodeModalContent');

  const { t } = useTranslation();
  const [pin, setPin] = useState('');
  const [validateBusy, setValidateBusy] = useState(false);
  const [forgotPinBusy, setForgotPinBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSetPinCodeModalContentOpen, setIsSetPinCodeModalContentOpen] = useState(false);
  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();

  const validatePinCode = useCallback(async () => {
    setValidateBusy(true);
    try {
      const validated = await validateRentalPinCode(pin);
      if (!validated) {
        setErrorMessage(t('SETTINGS_LOCKER_WRONG_PIN_ERROR'));
      } else {
        setIsSetPinCodeModalContentOpen(true);
      }
    } catch (err) {
      logger.error(`Failed to deactivate PIN for user`, err);
      setErrorMessage(t('SETTINGS_LOCKER_WRONG_PIN_ERROR'));
    } finally {
      setValidateBusy(false);
    }
  }, [pin, logger, t]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validatePinCode();
  };

  useEffect(() => {
    if (open) {
      setErrorMessage('');
      setPin('');
    }
  }, [open]);

  const onForgotPinCode = useCallback(async () => {
    try {
      setForgotPinBusy(true);
      const [{ email }] = await Promise.all([getUser(), forgotPinCode()]);
      createPositiveSnackbar(t('SETTINGS_RENTAL_PIN_RESET_PIN_INFORMATION', { email }));
      onAbort();
    } catch (error) {
      logger.error('Failed to reset pin email', error);
      createNegativeSnackbar(t('ERROR_GENERIC_INFORMATION'));
    } finally {
      setForgotPinBusy(false);
    }
  }, []);

  return (
    <>
      <RadixModal.Content title={t('SETTINGS_LOCKER_CHANGE_PIN_CODE')}>
        <StyledDescription>{t('SETTINGS_LOCKER_ENTER_CURRENT_PIN_CODE')}</StyledDescription>
        <form onSubmit={onSubmit}>
          <StyledPinField
            value={pin}
            onValueChanged={(value) => {
              setPin(value);
            }}
            length={4}
            masked={true}
            state={errorMessage ? 'INVALID' : undefined}
            message={errorMessage}
          />
          <RadixModal.ButtonStrip>
            <Button
              type="button"
              variant="ghost"
              onClick={onForgotPinCode}
              isLoading={forgotPinBusy}
              disabled={forgotPinBusy}
            >
              {t('SETTINGS_RENTAL_PIN_FORGOT_BUTTON_LABEL')}
            </Button>
            <Button
              type="submit"
              isLoading={validateBusy}
              disabled={validateBusy}
              variant={pin.length === 4 ? 'primary' : 'secondary'}
            >
              {t('SETTINGS_LOCKER_SET_PIN_CODE_BUTTON_NEXT')}
            </Button>
          </RadixModal.ButtonStrip>
        </form>
      </RadixModal.Content>
      <RadixModal open={isSetPinCodeModalContentOpen} onOpenChange={(isOpen) => {}}>
        <SetPinCodeModalContent
          oldPin={pin}
          open={isSetPinCodeModalContentOpen}
          onDone={() => {
            setIsSetPinCodeModalContentOpen(false);
            onDone();
          }}
          onAbort={() => {
            setIsSetPinCodeModalContentOpen(false);
            onAbort();
          }}
        />
      </RadixModal>
    </>
  );
};
